//react router 
export const CDR_DASHBOARD_ROUTE = {
    URL: '/cdrdashboard',
    CONTEXT_NAME: 'cdrdashboard',
    MULTILOGIN: 'false',
    MINIAPP_BASE_URL: '/cdrdashboard'
}

//user events
export const GLOBAL_EVENTS = {
    USER_AUTHENTICATED: 'user_authenticated',
    GLOBAL_ERROR: 'GLOBAL_ERROR',
    LOGOUT: 'LOGOUT'
}

//session storage
export const ENV = 'env';
export const APP = 'application';
export const CLIENTID = 'clientId';
export const AUTHSTRAT = 'authStrat';
export const MULTILOGIN = 'multiLogin'
export const BEARER_TOKEN = 'bearerToken';

//auth strats
export const IB = 'ib';
export const NABC = 'nabc';
export const LOGIN_SELECTOR = 'login-selector';