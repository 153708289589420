export const getRevokeUrl = (env: string) => {
    switch (env) {
        case 'dev':
            return 'https://customer.api-sit1.nabdev.com.au'
        case 'sit':
            return 'https://customer.api-sit5.nabdev.com.au'
        case 'prod':
            return 'https://customer.api.nab.com.au'
        default:
            return 'https://customer.api.nab.com.au'
    }
}